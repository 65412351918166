import React, {} from 'react';
import './Project.css';
import useImage from './hooks/useImage'


// const STYLES = ['service--primary', 'service--blue', 'service--black'];

export const Project = ({
    children, 
    serviceStyle, 
    data,
}) => {
    // const checkServiceStyle = STYLES.includes(serviceStyle) 
    //     ? serviceStyle 
    //     : STYLES[0];

    // console.log(data, key, i)

    const { image } = useImage(data.img);
    // console.log(image);


    return (
        <div 
            className={data.side==='left' ? 'project-container' : 'project-container--reverse'}
        >
            <div className='project-visual'>
                <img className='project-image' alt='project-visual' src={image} /> 
            </div>
            <div className='project-text'>
                <div className='project-title'>{data.title}</div>
                <div className='project-line'></div>
                <div className='project-description'>{data.description}</div>
            </div>
        </div>
    )
}