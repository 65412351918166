import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const getBasename = path => path.substr(0, path.lastIndexOf('/'));
  // const getBasename = function(path){
  //   console.log(path);
  //   return path.substr(0, path.lastIndexOf('/'));
  // }

  return (
    <>
      <Router basename={getBasename(window.location.pathname)}>
        <Navbar />
        <Routes>
          <Route exact path='/' element={< Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
