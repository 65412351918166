import React from 'react';
import './Sep.css';

// const STYLES = ['title--primary', 'title--white', 'title--black'];

const Offer = ({
    children, 
    titleStyle, 
}) => {
    // const checkTitleStyle = STYLES.includes(titleStyle) 
    //     ? titleStyle 
    //     : STYLES[0];


    return (
        <div className='sep'>
        </div>
    )
}

export default Offer;