import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import './Navbar.css';
import {ReactComponent as LogoSvg} from '../assets/logo.svg';
import axios from 'axios'

function Navbar() {
    // const API_URL = process.env.REACT_APP_API_URL + 'lang/'
    const [langs, setLangs] = useState([])
    const [cur_lang, setCurLang] = useState('en')

    async function getData() {
        // const response = await axios.get(API_URL)

        let langs = [{'text': 'en'}, {'text': 'nl'}]
        setLangs(langs)
        global.config = {langs: langs};

        // console.log('global data set', response.data)

        if (localStorage.language) {
            setCurLang(localStorage.language);
        }

        // console.log( cur_lang )
    }

    const changeLang = (ev) => {

        const lang = ev.target.lang;
        console.log(lang)
        // setCurLang

        setCurLang(lang);
        localStorage.setItem('language', lang);

        window.location.reload(false);
    }

    useEffect(() => {
        getData();
    }, [])

    /////////////////

    const [click, setClick] = useState(false);
    const [clickLangDropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const toggleLangDropdown = () => setDropdown(!clickLangDropdown);

    // const currentLang = "en";

    return (
        <>
            <nav className="navbar">
                <div className='navbar-container'>

                    <Link to="#top" className='navbar-logo' onClick={closeMobileMenu}>
                        <LogoSvg className='navbar-logo-svg'/>
                    </Link>

                    <div className='navbar-control-container'>

                        <div className="menu-icon" onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className='nav-item'>
                                <HashLink to='#what-we-do' className='nav-links nav-links-btn' onClick={closeMobileMenu}>
                                    What we do
                                </HashLink>
                            </li>
                            <li className='nav-item'>
                                <HashLink to='#portfolio' className='nav-links nav-links-btn' onClick={closeMobileMenu}>
                                    Portfolio
                                </HashLink>
                            </li>
                            <li className='nav-item'>
                                <HashLink to='#bottom' className='nav-links nav-links-btn' onClick={closeMobileMenu}>
                                    Contact us
                                </HashLink>
                            </li>

                            <li className='nav-item'>
                                <Link to='/' className='nav-links nav-links-lang caps' onClick={() => {
                                    toggleLangDropdown();
                                }}>


                                    {langs.map((lang, index) => (

                                        <span
                                            key={index}
                                            className={cur_lang === lang.text ? "nav-lang-btn-active" : "nav-lang-btn"}
                                            lang={lang.text}
                                            onClick={changeLang}
                                        >
                                            {lang.text}
                                        </span>

                                    ))}

                                    <i className="nav-lang-arrow fa-solid fa-2xs fa-caret-down"></i>
                                    <div className={clickLangDropdown ? "nav-lang-dropdown" : "nav-lang-dropdown-hide"}>

                                        {langs.map((lang,index) => (

                                            <div
                                                key={index}
                                                className={cur_lang === lang.text ? 'nav-lang-dropdown-list-hide' : 'nav-lang-dropdown-list'}
                                                lang={lang.text}
                                                onClick={changeLang}
                                            >
                                                {lang.text}
                                            </div>

                                        ))}

                                    </div>
                                </Link>
                            </li>
                        </ul>

                    </div>

                </div>
                <div className="navbar-ornaments-left"></div>
                <div className="navbar-ornaments-right"></div>
            </nav>
        </>
    )
}

export default Navbar
