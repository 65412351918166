import React, {useState} from 'react';
import './Service.css';
import useImage from './hooks/useImage'


const STYLES = ['service--primary', 'service--blue', 'service--black'];

export const Service = ({
    children, 
    serviceStyle, 
    data
}) => {
    const checkServiceStyle = STYLES.includes(serviceStyle) 
        ? serviceStyle 
        : STYLES[0];

    let description = data.subtitle;
    // console.log( 'description:', description );
    const visual = data.img;

    const [opened, setOpened] = useState(false);
    const toggleService = () => {
        setOpened(!opened);
        // console.log('toggle service', opened)

    }

    // console.log( 'service data:', data );

    // if(data.description.length > 1){
    //     description += '---------';
    // }

    const { image } = useImage(visual);


    return (
        <div 
            className={`service-container ${checkServiceStyle}`}
            onClick={toggleService}
        >
            <div className='service-center'>
                
                <div className='service-main'>
                    <div className='service-visual service-top'>
                        {/* <LogoSvg className='navbar-logo-svg' /> */}
                        {/* visual */}
                        <img alt="service-icon" src={image} />
                    </div>

                    <div className='serice-text service-top'>
                        <div className='service-title'>{data.title}</div>
                    </div>
                    
                    <div className='service-icon service-top'>
                        <div className={opened ? "hide" : ""}><i className="fa-solid fa-plus"></i></div>
                        <div className={opened ? "" : "hide"}><i className="fa-solid fa-minus"></i></div>
                    </div>
                </div>
                
                <div className={opened ? "service-description" : "service-description serice-hide"}>
                    {/* {description.map(txt => <p key={txt}>{txt}</p>)} */}
                    <div className='multiline'>
                        {description}
                    </div>
                </div>

            </div>
        </div>
    )
}


// map1 = array1.map(x => x * 2);
// let result = arr.map(country => `${country}...here be dragons`);
// console.log(result);