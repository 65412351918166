import React from 'react';
import './Title.css';

const STYLES = ['title--primary', 'title--white', 'title--black'];

export const Title = ({
    children, 
    titleStyle, 
}) => {
    const checkTitleStyle = STYLES.includes(titleStyle) 
        ? titleStyle 
        : STYLES[0];


    return (
        <div 
            className={`title-container ${checkTitleStyle}`}
        >
            <i className="fa-solid fa-minus title-icon"></i>
            <span className='title-text'>{children}</span>
            <i className="fa-solid fa-minus title-icon"></i>
        </div>
    )
}
