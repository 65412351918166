import {React, useState, useEffect,useRef} from 'react'
import axios from 'axios'
import './Bottom.css';
import { Link } from 'react-router-dom'
// import {Button} from './Button'

function Bottom() {
    const API_URL = process.env.REACT_APP_API_URL + 'bottom/'

    let cur_lang = 'en';
    const setLangFt = ()=>{
        if(localStorage.language){
            cur_lang = localStorage.language;
        }
    }
    
    // const [text, setText] = useState('')
    const [data, setData] = useState([])
    const myRef = useRef(null);
    // const [icon_1, setIcon1] = useState('')
    // const [url_1, setUrl1] = useState('')


    async function getData(){
        const response = await axios.get(API_URL)
            .then( (response)=>{
                setData(response.data[0]);
                myRef.current.innerHTML = response.data[0].text
            } )
            .catch( (error)=>{
                console.log(error)
            } )
    }

    useEffect(() => {
        setLangFt();
        getData();
    }, [])

    return (
        <div className='bottom'>
            <div className='hor-wrap bottom-container'>
                <div className=''>
                    <div className=''>
                        <div ref={myRef}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bottom