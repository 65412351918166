import {React, useState, useEffect} from 'react'
import axios from 'axios'
import {Title} from '../Title';
import {Service} from '../Service';
import './Solutions.css';

function Solutions() {
    const API_URL = process.env.REACT_APP_API_URL + 'solutions/'
    
    const [data, setData] = useState([])

    let cur_lang = 'en';
    const setLangFt = ()=>{
        if(localStorage.language){
            cur_lang = localStorage.language;
        }
    }

    async function getData(){
        const response = await axios.get(API_URL)
            .then( (response)=>{
                for(var i=0; i<response.data.length; i++){
                    response.data[i].title = response.data[i].title[cur_lang];
                    response.data[i].subtitle = response.data[i].subtitle[cur_lang];
                }
                setData(response.data)
            } )
            .catch( (error)=>{
                console.log(error)
            } )
    }

    useEffect(() => {
        setLangFt();
        getData();
    }, [])

    return(
        <div className='solutions'>

            <Title 
                titleStyle='title--black'
            >
                Tech Solutions
            </Title>


            <div className='services-container'>
                {data.map((solution) => (
                    <Service 
                        key={solution._id}
                        serviceStyle='service--blue'
                        type='title--list'
                        icon='workplace'
                        data={solution}
                    />
                ))}
            </div>
            
        </div>
    )
}

export default Solutions;