import React from 'react';
// import '../../App.css';
import Hero from '../Hero';
import Services from './Services';
import Solutions from './Solutions';
import Portfolio from './Portfolio';
import Merits from '../Merits';
import Partners from '../Partners';
import Offer from '../Offer';
import Contacts from '../Contacts';
import Bottom from '../Bottom';
import Sep from '../Sep';

function Home() {
    return(
        <>
            <h2 id="top"></h2>
            <Hero />
            <h2 id="services"></h2>
            <Services />
            <Sep />
            <Offer />
            <Sep />
            {/*<Solutions />*/}
            {/*<Sep />*/}
            <h2 id="portfolio"></h2>
            <Portfolio />
            <Merits />
            {/*<Partners />*/}
            {/*<Contacts />*/}
            <h2 id="bottom"></h2>
            <Bottom />
        </>
    )
}

export default Home;
