import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--outline-blue', 'contacts-btn'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
    children, 
    type, 
    onClick, 
    openUrl, 
    buttonStyle, 
    buttonStyle2, 
    buttonSize,
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) 
        ? buttonStyle 
        : STYLES[0];
    const checkButtonStyle2 = STYLES.includes(buttonStyle2) 
        ? buttonStyle2 
        : '';

    const checkButtonSize = SIZES.includes(buttonSize)
        ? buttonSize
        : SIZES[0];

    openUrl = openUrl ? openUrl : ''; 
    //console.log('checkButtonStyle', buttonStyle)

    return (

        <Link to={openUrl} className={`btn-mobile ${checkButtonStyle2}`}>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
                <span className='btn-text'>{children}</span>
                <i className="fa-solid fa-circle-chevron-right btn-icon"></i>
            </button>
        </Link>

    )
}