import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );

ReactDOM.createRoot( document.getElementById('root') ).render(
  <App />
);