import {React, useState, useEffect} from 'react'
import axios from 'axios'
import './Portfolio.css';
import {Title} from '../Title';
import {Project} from '../Project';


function Portfolio() {
    const API_URL = process.env.REACT_APP_API_URL + 'portfolios/'
    
    const [data, setData] = useState([])

    let cur_lang = 'en';
    const setLangFt = ()=>{
        if(localStorage.language){
            cur_lang = localStorage.language;
        }
    }

    async function getData(){
        const response = await axios.get(API_URL)
            .then( (response)=>{
                for(var i=0; i<response.data.length; i++){
                    response.data[i].title = response.data[i].title[cur_lang];
                    response.data[i].description = response.data[i].description[cur_lang];
                }
                var newData = adjustData(response.data)
                setData(newData)
            } )
            .catch( (error)=>{
                console.log(error)
            } )
    }

    function adjustData(data){
        for(let i=0; i<data.length; i++){
            data[i].side = i%2==1 ? 'right' : 'left';
        }
        // console.log('data', data);
        return data;
    }

    useEffect(() => {
        setLangFt();
        getData();
    }, [])

    return(
        <div className='portfolio'>

            <div className="portfolio-ornaments-left"></div>
            <div className="portfolio-ornaments-right"></div>

            <div className='portfolio-container hor-wrap'>
                
                <Title 
                    titleStyle='title--white'
                >
                    Portfolio
                </Title>
                
                <div className='projects-container hor-wrap'>

                <div className='services-container'>
                    {/* {data.map((portfolio) => (
                        <Project 
                            key={portfolio._id}
                            data={portfolio}
                        />
                    ))} */}
                    {data.map( (portfolio, i) =>
                        <div key={portfolio._id}>
                            <Project 
                                key={portfolio._id} 
                                data={portfolio} 
                            />
                            {
                                ( i != data.length-1 ) ? <div className='projects-sep'></div> : <></>
                            }
                        </div>
                    )}
                </div>
                

                    {/* <Project
                        data={projectsList[0]}
                        >
                    </Project>

                    <div className='projects-sep'></div>
                    */}

                </div>

            </div>

        </div>
    )
}

export default Portfolio;