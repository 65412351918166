import {React, useState, useEffect} from 'react'
import axios from 'axios'
import './Hero.css';
import {Button} from './Button'

function Hero() {
    const API_URL = process.env.REACT_APP_API_URL + 'slides/'
    const [data, setData] = useState([])

    let cur_lang = 'en';
    const setLangFt = ()=>{
        if(localStorage.language){
            cur_lang = localStorage.language;
        }
    }



    async function getData(){
        const response = await axios.get(API_URL)
            .then( (response)=>{
                // cms only proviedes 1 slide, without language
                response.data[0].t1 = response.data[0].t1[cur_lang]
                response.data[0].t2 = response.data[0].t2[cur_lang]
                response.data[0].t3 = response.data[0].t3[cur_lang]
                response.data[0].btn_text = response.data[0].btn_text[cur_lang]
                setData(response.data[0])
            } )
            .catch( (error)=>{
                console.log(error)
            } )
    }

    useEffect(() => {
        setLangFt();
        getData();
    }, [])

    function openUrl(){
        window.open(data.btn_landing, "_self");
    }

    return (
        <>
            <div className="hero-container">
                <div className='hero-bg'>
                    <div className='hero-bg-ornaments'></div>
                </div>
                <div className='hero-content'>
                    <div className='hero-texts'>
                        <div className='hero-texts-center'>
                            <p className="hero-text-1">{data.t1}</p>
                            <p className="hero-text-2">{data.t2}</p>
                            <div className='hero-description'>
                                <div className='blue-vertical'></div>
                                <div className='hero-text-3'>{data.t3}</div>
                            </div>
                            {
                                data.show_btn ?
                                <div className='hero-btns'>
                                    <Button 
                                        className='btns' 
                                        buttonStyle='btn--primary'
                                        buttonSize='btn--large'
                                        onClick={openUrl}
                                    >
                                        {data.btn_text}
                                    </Button>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='hero-visual'>
                        <div className='hero-visual-art'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero