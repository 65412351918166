import {React, useState, useEffect} from 'react'
import axios from 'axios'
import './Merits.css';


function Merits() {
    const API_URL = process.env.REACT_APP_API_URL + 'merits/'
    
    const [data, setData] = useState([])

    let cur_lang = 'en';
    const setLangFt = ()=>{
        if(localStorage.language){
            cur_lang = localStorage.language;
        }
    }

    async function getData(){
        const response = await axios.get(API_URL)
            .then( (response)=>{
                for(var i=0; i<response.data.length; i++){
                    response.data[i].number = response.data[i].number[cur_lang];
                    response.data[i].text = response.data[i].text[cur_lang];
                }
                setData(response.data)
            } )
            .catch( (error)=>{
                console.log(error)
            } )
    }

    useEffect(() => {
        setLangFt();
        getData();
    }, [])

    return(
        <div className='merits'>
            <div className='merits-container hor-wrap'>

                {data.map((merit) => (
                    <div className='merits-block' key={merit._id}>
                        <div className='merits-number'>{merit.number}</div>
                        <div className='merits-line'></div>
                        <div className='merits-text'>{merit.text}</div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default Merits;