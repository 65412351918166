
import './Offer.css';
import {Button} from './Button'
import {React, useState, useEffect} from 'react'
import axios from 'axios'


function Offer (){
    const API_URL = process.env.REACT_APP_API_URL + 'offer/'
    const [data, setData] = useState([])

    let cur_lang = 'en';
    const setLangFt = ()=>{
        if(localStorage.language){
            cur_lang = localStorage.language;
        }
    }

    async function getData(){
        const response = await axios.get(API_URL)
            .then( (response)=>{
                response.data[0].text = response.data[0].text[cur_lang]
                response.data[0].btn_text = response.data[0].btn_text[cur_lang]
                setData(response.data[0])
            } )
            .catch( (error)=>{
                console.log(error)
            } )
    }

    useEffect(() => {
        setLangFt();
        getData();
    }, [])

    function openUrl(){
        window.location = 'mailto:'+data.btn_landing
        // window.open(data.btn_landing, "_self");
    }

    return (
        <div className='offer'>
            <div className='offer-container hor-wrap'>
                <div className='offerText'>{data.text}</div>
                {
                    data.show_btn ?
                    <div className='hero-btns'>
                        <Button 
                            className='btns' 
                            buttonStyle='btn--primary'
                            buttonSize='btn--large'
                            onClick={openUrl}
                        >
                            {data.btn_text}
                        </Button>
                    </div>
                    : <></>
                }
            </div>
        </div>
    )
}

export default Offer;